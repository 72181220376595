/**
 * Site header
 */
.site-header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-header-title {
  display: flex;
  align-items: center;
}

.site-avatar {
  float: left;
  border-radius: 8px;
  margin: $spacing-unit $spacing-unit $spacing-unit 0;
}

.site-header {
  border-top: 5px solid $grey-color-dark;
  min-height: 56px;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  display: flex;
  flex-flow: column nowrap;

  .name {
    font-size: 26px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &:hover {
      color: $blue-color;
      text-decoration: none;
    }

    &,
    &:visited {
      color: $grey-color-dark;
    }
  }

  .pronouns {
    font-size: 14px;
  }
}

.site-nav {
  float: right;
  line-height: 56px;

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the first one
    &:not(:first-child) {
      margin-left: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: 30px;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        width: 18px;
        height: 15px;

        path {
          fill: $grey-color-dark;
        }
      }
    }

    .trigger {
      clear: both;
      display: none;
    }

    &:hover .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;
    }
  }
}

/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-light;
  padding: $spacing-unit 0;
}

.footer-heading {
  font-size: 18px;
  margin-bottom: $spacing-unit / 2;
}

.footer-name {
  background: none;
  border: 0;
  color: $grey-color;
  margin: $spacing-unit / 2 0 0 0;
  overflow-x: auto;
  padding: 0;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width: calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width: calc(100% - (#{$spacing-unit} / 2));
  }
}

/**
 * Page content
 */
table {
  max-width: 90%;
  margin-bottom: 20px;
  border: 1px solid #dedede;
  border-spacing: 0;

  thead,
  tbody,
  tfoot {
    tr {
      th,
      td {
        padding: 8px;
        line-height: 1.42857143;
        vertical-align: top;
        border-top: 1px solid #ddd;
      }
    }
  }

  thead {
    tr {
      th {
        vertical-align: bottom;
        border-bottom: 2px solid #ddd;
        text-transform: none;
        background-color: #777;
        color: #fff;
        text-align: left;
      }
    }

    tr:first-child {
      th {
        border-top: 0;
      }
    }
  }

  tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }

  tr {
    td {
      hyphens: auto;
    }
  }
}

.external-link::after {
  content: " »";
}

.figure-caption {
  color: gray;
  font-size: 80%;
  text-align: center;
}

.normal-quote {
  font-style: normal;
  letter-spacing: normal;
}

.page-content {
  padding: $spacing-unit 0;

  p {
    line-height: 28px;
  }
}

.page-heading {
  font-size: 20px;
}

.post h2 {
  margin: 0;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  display: block;
  font-size: $small-font-size;
  color: $grey-color;
  margin-bottom: $spacing-unit / 2;
}

.post-link {
  display: block;
  font-size: 24px;
}

.post-read-more {
  display: block;
  margin: $spacing-unit / 2 0;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}

/**
 * Projects
 */
.projects-project {
  display: block;
  clear: both;
  border-bottom: 1px #ccc dotted;
  min-height: 240px;
  padding: $spacing-unit 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }
}

.projects-screenshot {
  float: left;
  margin: 0 $spacing-unit $spacing-unit 0;
  border: 1px #ccc solid;
}
